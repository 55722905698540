(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sport-config/assets/javascripts/sports/football.js') >= 0) return;  svs.modules.push('/components/sportinfo/sport-config/assets/javascripts/sports/football.js');
"use strict";


const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const statusTexts = {
  [SportEventStatus.Pause]: {
    full: 'Paus',
    short: 'PAUS'
  },
  [SportEventStatus.AwaitingPenalties]: {
    full: 'Inväntar straffar',
    short: 'PAUS'
  },
  [SportEventStatus.Penalties]: {
    full: 'Straffar (STR)',
    short: 'STR'
  },
  [SportEventStatus.AwaitingExtratime]: {
    full: 'Inväntar extratid',
    short: 'PAUS'
  },
  [SportEventStatus.Halftime]: {
    full: 'Halvtid (HT)',
    short: 'HT'
  },
  [SportEventStatus.Fulltime]: {
    full: 'Fulltid (FT)',
    short: 'FT'
  },
  [SportEventStatus.Extratime]: {
    full: 'Extratid (ET)',
    short: 'ET'
  },
  [SportEventStatus.Overtime]: {
    full: 'Övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.EndedAfterExtratime]: {
    full: 'Slut efter extratid (ET)',
    short: 'ET'
  },
  [SportEventStatus.EndedAfterPenalties]: {
    full: 'Slut efter straffar (STR)',
    short: 'STR'
  }
};
const matchMinutesOverrides = [SportEventStatus.Halftime, SportEventStatus.AwaitingPenalties, SportEventStatus.AwaitingExtratime, SportEventStatus.Extratime, SportEventStatus.Overtime, SportEventStatus.Penalties];
const timeOffsets = {
  [SportEventStatus.FirstHalf]: {
    start: 0,
    end: 45
  },
  [SportEventStatus.SecondHalf]: {
    start: 45,
    end: 90
  },
  [SportEventStatus.FirstExtraPeriod]: {
    start: 90,
    end: 105
  },
  [SportEventStatus.SecondExtraPeriod]: {
    start: 105,
    end: 120
  }
};
const config = {
  statusTexts,
  timeOffsets,
  matchMinutesOverrides
};
setGlobal('svs.components.sportinfo.sportConfig.sports.Football', config);

 })(window);