(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sport-config/assets/javascripts/sports/default.js') >= 0) return;  svs.modules.push('/components/sportinfo/sport-config/assets/javascripts/sports/default.js');
"use strict";


const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const statusTexts = {
  [SportEventStatus.Undefined]: {
    full: '-',
    short: '-'
  },
  [SportEventStatus.NotStarted]: {
    full: '-',
    short: '-'
  },
  [SportEventStatus.AboutToStart]: {
    full: '-',
    short: '-'
  },
  [SportEventStatus.Live]: {
    full: 'Live',
    short: 'LIVE'
  },
  [SportEventStatus.FirstPeriod]: {
    full: 'Första perioden',
    short: '1:A'
  },
  [SportEventStatus.SecondPeriod]: {
    full: 'Andra perioden',
    short: '2:A'
  },
  [SportEventStatus.ThirdPeriod]: {
    full: 'Tredje perioden',
    short: '3:E'
  },
  [SportEventStatus.FirstHalf]: {
    full: 'Första halvlek',
    short: '1:A'
  },
  [SportEventStatus.SecondHalf]: {
    full: 'Andra halvlek',
    short: '2:A'
  },
  [SportEventStatus.FirstQuarter]: {
    full: '1:A quarter',
    short: 'Q1'
  },
  [SportEventStatus.SecondQuarter]: {
    full: '2:A quarter',
    short: 'Q2'
  },
  [SportEventStatus.ThirdQuarter]: {
    full: '3:E quarter',
    short: 'Q3'
  },
  [SportEventStatus.FourthQuarter]: {
    full: '4:E quarter',
    short: 'Q4'
  },
  [SportEventStatus.FirstInning]: {
    full: 'Första omgången',
    short: '1:A'
  },
  [SportEventStatus.SecondInning]: {
    full: 'Andra omgången',
    short: '2:A'
  },
  [SportEventStatus.ThirdInning]: {
    full: 'Tredje omgången',
    short: '3:E'
  },
  [SportEventStatus.FourthInning]: {
    full: 'Fjärde omgången',
    short: '4:E'
  },
  [SportEventStatus.FifthInning]: {
    full: 'Femte omgången',
    short: '5:E'
  },
  [SportEventStatus.SixthInning]: {
    full: 'Sjätte omgången',
    short: '6:E'
  },
  [SportEventStatus.SeventhInning]: {
    full: 'Sjunde omgången',
    short: '7:E'
  },
  [SportEventStatus.EighthInning]: {
    full: 'Åttånde omgången',
    short: '8:E'
  },
  [SportEventStatus.NinthInning]: {
    full: 'Nionde omgången',
    short: '9:E'
  },
  [SportEventStatus.Overtime]: {
    full: 'Övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FirstExtraPeriod]: {
    full: 'Första extraperioden',
    short: '1:A ET'
  },
  [SportEventStatus.SecondExtraPeriod]: {
    full: 'Andra extraperioden',
    short: '2:A ET'
  },
  [SportEventStatus.ThirdExtraPeriod]: {
    full: 'Tredje extraperioden',
    short: '3:E ET'
  },
  [SportEventStatus.AwaitingPenalties]: {
    full: 'Inväntar straffar',
    short: 'PAUS'
  },
  [SportEventStatus.AwaitingExtratime]: {
    full: 'Inväntar extratid',
    short: 'PAUS'
  },
  [SportEventStatus.Penalties]: {
    full: 'Straffar (STR)',
    short: 'STR'
  },
  [SportEventStatus.Pause]: {
    full: 'Paus',
    short: 'PAUS'
  },
  [SportEventStatus.Interrupted]: {
    full: 'Avbruten',
    short: 'AVBR'
  },
  [SportEventStatus.Abandoned]: {
    full: 'Avbruten',
    short: 'AVBR'
  },
  [SportEventStatus.Postponed]: {
    full: 'Uppskjuten',
    short: 'UPPS'
  },
  [SportEventStatus.Delayed]: {
    full: 'Försenad',
    short: 'FÖRS'
  },
  [SportEventStatus.Cancelled]: {
    full: 'Inställd',
    short: 'INST'
  },
  [SportEventStatus.Ended]: {
    full: 'Fulltid (FT)',
    short: 'FT'
  },
  [SportEventStatus.Closed]: {
    full: 'Stängd',
    short: 'STÄNGD'
  },
  [SportEventStatus.Halftime]: {
    full: 'Halvtid (HT)',
    short: 'HT'
  },
  [SportEventStatus.Fulltime]: {
    full: 'Fulltid (FT)',
    short: 'FT'
  },
  [SportEventStatus.Extratime]: {
    full: 'Extratid (ET)',
    short: 'ET'
  },
  [SportEventStatus.EndedAfterExtratime]: {
    full: 'Slut efter extratid (ET)',
    short: 'ET'
  },
  [SportEventStatus.EndedAfterPenalties]: {
    full: 'Slut efter straffar (STR)',
    short: 'STR'
  },
  [SportEventStatus.FallbackFourthPeriod]: {
    full: 'Fjärde perioden',
    short: '4:E'
  },
  [SportEventStatus.FallbackFifthPeriod]: {
    full: 'Femte perioden',
    short: '5:E'
  },
  [SportEventStatus.FallbackFirstSet]: {
    full: 'Första set',
    short: '1:A'
  },
  [SportEventStatus.FallbackSecondSet]: {
    full: 'Andra set',
    short: '2:A'
  },
  [SportEventStatus.FallbackThirdSet]: {
    full: 'Tredje set',
    short: '3:E'
  },
  [SportEventStatus.FallbackFourthSet]: {
    full: 'Fjärde set',
    short: '4:e'
  },
  [SportEventStatus.FallbackFifthSet]: {
    full: 'Femte set',
    short: '5:E'
  },
  [SportEventStatus.FallbackExtraTimeHalftime]: {
    full: 'Halvtid Extratid',
    short: 'HT/ET'
  },
  [SportEventStatus.FallbackSuspended]: {
    full: 'Speluppehåll',
    short: 'UPPH'
  },
  [SportEventStatus.FallbackWalkover]: {
    full: 'Walkover',
    short: 'WO'
  },
  [SportEventStatus.FallbackParticipantRetired]: {
    full: 'Deltagare pensionerad',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackInFirstBreak]: {
    full: 'Första paus',
    short: 'PAUS'
  },
  [SportEventStatus.FallbackInSecondBreak]: {
    full: 'Andra paus',
    short: 'PAUS'
  },
  [SportEventStatus.FallbackInThirdBreak]: {
    full: 'Tredje paus',
    short: 'PAUS'
  },
  [SportEventStatus.FallbackGoldenSet]: {
    full: 'Golden set',
    short: 'GOLD'
  },
  [SportEventStatus.FallbackSixthSet]: {
    full: 'Sjätte set',
    short: '6:E'
  },
  [SportEventStatus.FallbackSeventhSet]: {
    full: 'Sjunde set',
    short: '7:E'
  },
  [SportEventStatus.FallbackFrame1]: {
    full: 'Frame 1',
    short: '1:A'
  },
  [SportEventStatus.FallbackFrame2]: {
    full: 'Frame 2',
    short: '2:A'
  },
  [SportEventStatus.FallbackFrame3]: {
    full: 'Frame 3',
    short: '3:E'
  },
  [SportEventStatus.FallbackFrame4]: {
    full: 'Frame 4',
    short: '4:E'
  },
  [SportEventStatus.FallbackFrame5]: {
    full: 'Frame 5',
    short: '5:E'
  },
  [SportEventStatus.FallbackFrame6]: {
    full: 'Frame 6',
    short: '6:E'
  },
  [SportEventStatus.FallbackFrame7]: {
    full: 'Frame 7',
    short: '7:E'
  },
  [SportEventStatus.FallbackFrame8]: {
    full: 'Frame 8',
    short: '8:E'
  },
  [SportEventStatus.FallbackFrame9]: {
    full: 'Frame 9',
    short: '9:E'
  },
  [SportEventStatus.FallbackFrame10]: {
    full: 'Frame 10',
    short: '10:E'
  },
  [SportEventStatus.FallbackFrame11]: {
    full: 'Frame 11',
    short: '11:E'
  },
  [SportEventStatus.FallbackFrame12]: {
    full: 'Frame 12',
    short: '12:E'
  },
  [SportEventStatus.FallbackFrame13]: {
    full: 'Frame 13',
    short: '13:E'
  },
  [SportEventStatus.FallbackFrame14]: {
    full: 'Frame 14',
    short: '14:E'
  },
  [SportEventStatus.FallbackFrame15]: {
    full: 'Frame 15',
    short: '15:E'
  },
  [SportEventStatus.FallbackFrame16]: {
    full: 'Frame 16',
    short: '16:E'
  },
  [SportEventStatus.FallbackFrame17]: {
    full: 'Frame 17',
    short: '17:E'
  },
  [SportEventStatus.FallbackFrame18]: {
    full: 'Frame 18',
    short: '18:E'
  },
  [SportEventStatus.FallbackFrame19]: {
    full: 'Frame 19',
    short: '19:E'
  },
  [SportEventStatus.FallbackFrame20]: {
    full: 'Frame 20',
    short: '20:E'
  },
  [SportEventStatus.FallbackFrame21]: {
    full: 'Frame 21',
    short: '21:A'
  },
  [SportEventStatus.FallbackFrame22]: {
    full: 'Frame 22',
    short: '22:A'
  },
  [SportEventStatus.FallbackFrame23]: {
    full: 'Frame 23',
    short: '23:E'
  },
  [SportEventStatus.FallbackFrame24]: {
    full: 'Frame 24',
    short: '24:E'
  },
  [SportEventStatus.FallbackFrame25]: {
    full: 'Frame 25',
    short: '25:E'
  },
  [SportEventStatus.FallbackFrame26]: {
    full: 'Frame 26',
    short: '26:E'
  },
  [SportEventStatus.FallbackFrame27]: {
    full: 'Frame 27',
    short: '27:E'
  },
  [SportEventStatus.FallbackFrame28]: {
    full: 'Frame 28',
    short: '28:E'
  },
  [SportEventStatus.FallbackFrame29]: {
    full: 'Frame 29',
    short: '29:E'
  },
  [SportEventStatus.FallbackFrame30]: {
    full: 'Frame 30',
    short: '30:E'
  },
  [SportEventStatus.FallbackFrame31]: {
    full: 'Frame 31',
    short: '31:A'
  },
  [SportEventStatus.FallbackFrame32]: {
    full: 'Frame 32',
    short: '32:A'
  },
  [SportEventStatus.FallbackFrame33]: {
    full: 'Frame 33',
    short: '33:E'
  },
  [SportEventStatus.FallbackFrame34]: {
    full: 'Frame 34',
    short: '34:E'
  },
  [SportEventStatus.FallbackFrame35]: {
    full: 'Frame 35',
    short: '35:E'
  },
  [SportEventStatus.FallbackFrame36]: {
    full: 'Frame 36',
    short: '36:E'
  },
  [SportEventStatus.FallbackFrame37]: {
    full: 'Frame 37',
    short: '37:E'
  },
  [SportEventStatus.FallbackAwaitingSuddenDeath]: {
    full: 'Inväntar Sudden Death',
    short: 'PAUS'
  },
  [SportEventStatus.FallbackInSuddenDeath]: {
    full: 'Sudden Death',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackEndedAfterSuddenDeath]: {
    full: 'Slut efter övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackFirstInningsHomeTeam]: {
    full: 'Första omgången',
    short: '1:A'
  },
  [SportEventStatus.FallbackFirstInningsAwayTeam]: {
    full: 'Första omgången',
    short: '1:A'
  },
  [SportEventStatus.FallbackSecondInningsHomeTeam]: {
    full: 'Andra omgången',
    short: '2:A'
  },
  [SportEventStatus.FallbackSecondInningsAwayTeam]: {
    full: 'Andra omgången',
    short: '2:A'
  },
  [SportEventStatus.FallbackAwaitingSuperOver]: {
    full: 'Inväntar övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackInSuperOverHomeTeam]: {
    full: 'Övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackInSuperOverAwayTeam]: {
    full: 'Övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackEndedAfterSuperOver]: {
    full: 'Slut efter övertid (ÖT)',
    short: 'ÖT'
  },
  [SportEventStatus.FallbackStumps]: {
    full: 'Fulltid',
    short: 'FT'
  },
  [SportEventStatus.FallbackDefaulted]: {
    full: 'Avbruten',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackWalkoverHomeTeamWon]: {
    full: 'Walkover hemmavinst',
    short: 'WO'
  },
  [SportEventStatus.FallbackWalkoverAwayTeamWon]: {
    full: 'Walkover bortavinst',
    short: 'WO'
  },
  [SportEventStatus.FallbackParticipantRetiredHomeTeamWon]: {
    full: 'Deltagare pensionerad',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackParticipantRetiredAwayTeamWon]: {
    full: 'Deltagare pensionerad',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackDeFallbackDefaultedHomeTeamWonfaulted]: {
    full: 'Avbruten',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackDefaultedAwayTeamWon]: {
    full: 'Avbruten',
    short: 'AVBR'
  },
  [SportEventStatus.FallbackLunchBreak]: {
    full: 'Lunchpaus',
    short: 'PAUS'
  },
  [SportEventStatus.FallbackTeaBreak]: {
    full: 'Tepaus',
    short: 'PAUS'
  }
};

const config = {
  statusTexts
};
setGlobal('svs.components.sportinfo.sportConfig.sports.Default', config);

 })(window);