(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sport-config/assets/javascripts/sports/non-timebased.js') >= 0) return;  svs.modules.push('/components/sportinfo/sport-config/assets/javascripts/sports/non-timebased.js');
"use strict";


const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const statusTexts = {
  [SportEventStatus.Fulltime]: {
    full: 'Avslutad',
    short: 'SLUT'
  },
  [SportEventStatus.Ended]: {
    full: 'Avslutad',
    short: 'SLUT'
  }
};
const config = {
  statusTexts
};
setGlobal('svs.components.sportinfo.sportConfig.sports.NonTimebased', config);

 })(window);