(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sport-config/assets/javascripts/sports-config.js') >= 0) return;  svs.modules.push('/components/sportinfo/sport-config/assets/javascripts/sports-config.js');
"use strict";


const {
  SportTypes
} = svs.components.sport.sportTypes;
const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const {
  Football,
  Default,
  NonTimebased
} = svs.components.sportinfo.sportConfig.sports;
const {
  getSportInfoMatchState,
  SportinfoMatchState
} = svs.components.sportinfo.common.matchState;
const {
  getFormattedDate,
  getFormattedTime
} = svs.components.lbUtils.helpers.formatDate;
const sportTypeToConfig = {
  [SportTypes.Soccer]: Football,
  [SportTypes.Tennis]: NonTimebased,
  [SportTypes.Golf]: NonTimebased,
  [SportTypes.Speedway]: NonTimebased,
  [SportTypes.SkiingAlpine]: NonTimebased,
  [SportTypes.Formula1]: NonTimebased,
  [SportTypes.Athletics]: NonTimebased,
  [SportTypes.SkiingCrossCountry]: NonTimebased,
  [SportTypes.WinterOlympic]: NonTimebased,
  [SportTypes.Biathlon]: NonTimebased,
  [SportTypes.MartialArt]: NonTimebased,
  [SportTypes.Trotting]: NonTimebased,
  [SportTypes.Specialties]: NonTimebased,
  [SportTypes.SummerOlympic]: NonTimebased
};
const statusTimeout = {
  [SportTypes.Trotting]: 60 * 60 * 1000,
  [SportTypes.SkiingCrossCountry]: 60 * 60 * 12 * 1000,
  [SportTypes.Biathlon]: 60 * 60 * 12 * 1000
};


const hasMatchStartSurpassed = (sportType, matchStart, sportEventStatus) => {
  const now = new Date();
  const then = new Date(matchStart);
  return Boolean(statusTimeout[sportType] && now - then > statusTimeout[sportType] && sportEventStatus === SportEventStatus.NotStarted);
};


const getStatusText = _ref => {
  let {
    sportEventStatus,
    sportType,
    statusTime,
    statusTextLength = 'full',
    matchState,
    matchStart
  } = _ref;
  if (matchStart && matchState === SportinfoMatchState.NotStarted) {
    const hasSurpassed = hasMatchStartSurpassed(sportType, matchStart, sportEventStatus);
    if (hasSurpassed) {
      return {
        matchStatusText: NonTimebased.statusTexts[SportEventStatus.Ended][statusTextLength]
      };
    }
    if (sportEventStatus === SportEventStatus.Delayed) {
      return {
        matchStatusText: Default.statusTexts[SportEventStatus.Delayed][statusTextLength]
      };
    }
    return {
      matchStatusText: " ".concat(getFormattedDate(matchStart, true, true, true), " ").concat(getFormattedTime(matchStart), " "),
      formattedTime: getFormattedTime(matchStart),
      formattedDate: getFormattedDate(matchStart, true, true, true)
    };
  }
  const config = sportTypeToConfig[sportType];

  if (!config) {
    return {
      matchStatusText: Default.statusTexts[sportEventStatus][statusTextLength]
    };
  }

  if (statusTime && matchState === SportinfoMatchState.Ongoing) {
    if (config.matchMinutesOverrides && config.matchMinutesOverrides.includes(sportEventStatus)) {
      return {
        matchStatusText: config.statusTexts[sportEventStatus][statusTextLength]
      };
    }
    const timeOffset = config.timeOffsets && config.timeOffsets[sportEventStatus];
    if (timeOffset) {
      const statusDate = dateFns.setMilliseconds(new Date(statusTime), 0);
      const now = dateFns.setMilliseconds(new Date(), 0);
      let statusTimeDiff = dateFns.differenceInMinutes(now, statusDate, {
        roundingMethod: 'ceil'
      });
      statusTimeDiff += timeOffset.start + 1;
      if (statusTimeDiff > timeOffset.end) {
        return {
          matchStatusText: "".concat(timeOffset.end, "+'")
        };
      }
      return {
        matchStatusText: "".concat(statusTimeDiff, "'")
      };
    }
  }
  const statusText = config.statusTexts && config.statusTexts[sportEventStatus] && config.statusTexts[sportEventStatus][statusTextLength];
  if (statusText) {
    return {
      matchStatusText: statusText
    };
  }

  return {
    matchStatusText: Default.statusTexts[sportEventStatus][statusTextLength]
  };
};

const getStatusTextBySportEventAndLeague = (sportEvent, league) => {
  const statusText = sportEvent && league ? getStatusText({
    sportEventStatus: sportEvent.sportEventStatus,
    sportType: league.sportType,
    statusTime: sportEvent.statusTime,
    statusTextLength: 'short',
    matchState: getSportInfoMatchState(sportEvent.sportEventStatus),
    matchStart: sportEvent.matchStart
  }).matchStatusText : undefined;
  return statusText;
};
setGlobal('svs.components.sportinfo.sportConfig.helpers', {
  getStatusTextBySportEventAndLeague,
  getStatusText,
  hasMatchStartSurpassed
});

 })(window);